import {
  AccountTreeOutlined,
  ArrowForwardIos,
  CalendarMonthOutlined,
  GridViewOutlined,
  HourglassBottomOutlined,
  InsightsOutlined,
  TrackChangesOutlined,
} from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ContactsIcon from "@mui/icons-material/Contacts";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import LoginIcon from "@mui/icons-material/Login";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Stack,
  SwipeableDrawer,
  SxProps,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import { useUserData } from "contexts/UserProvider";
import React from "react";
import { useNavigate } from "react-router-dom";
import { black, darkBlue, highlight, white, yellow } from "../../App";
import { useIsSidebarOpen } from "../../contexts/SidebarProvider";
import { Logo } from "../../icons/logo";
import {
  Drawer,
  DrawerHeader,
  ListLink,
  Subtitle,
  drawerWidth,
  drawerWidthMobile,
} from "./Sidebar.utils";

const BurgerMenu = ({
  icon,
  color,
  sx,
}: {
  icon: React.ReactNode;
  edge?: "start";
  color?: "inherit";
  sx?: SxProps;
}) => {
  const [open, setOpen] = useIsSidebarOpen();

  return (
    <IconButton
      aria-label="open drawer"
      color={color}
      onClick={() => setOpen(!open)}
      sx={{ borderRadius: 0, ...sx }}
      disableRipple
    >
      {icon}
    </IconButton>
  );
};

const Username = ({ open }: { open: boolean }) => {
  const [user, setUser] = useUserData();
  const navigate = useNavigate();

  const logout = async () => {
    setUser(undefined);
    navigate("/", { replace: true });
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        border: `1px solid ${highlight}`,
        padding: "20px",
        marginTop: "16px",
      }}
    >
      <Avatar sx={{ bgcolor: yellow, width: 26, height: 26, fontSize: "12px" }}>
        {user?.username?.slice(0, 1).toUpperCase()}
      </Avatar>
      {open && (
        <>
          <Typography sx={{ color: white }} noWrap>
            {user?.username}
          </Typography>
          <IconButton sx={{ padding: 0 }} onClick={() => logout()}>
            <LoginIcon sx={{ color: white }} />
          </IconButton>
        </>
      )}
    </Stack>
  );
};

const Navigation = ({ open }: { open: boolean }) => {
  return (
    <>
      <List disablePadding={open}>
        <ListLink
          open={open}
          text="Dashboard"
          icon={<GridViewOutlined sx={{ color: white }} />}
          path="/"
        />
      </List>
      {open ? (
        <Subtitle text="Pages" />
      ) : (
        <Divider sx={{ background: white }} />
      )}
      <List disablePadding={open}>
        <ListLink
          open={open}
          text="Calendar"
          icon={<CalendarMonthOutlined sx={{ color: white }} />}
          path="/calendar"
        />
        <ListLink
          open={open}
          text="Tasks"
          icon={<DomainVerificationIcon sx={{ color: white }} />}
          path="/tasks"
        />
      </List>
      {open ? <Subtitle text="Data" /> : <Divider sx={{ background: white }} />}
      <List disablePadding={open}>
        <ListLink
          open={open}
          text="Clients"
          icon={<ContactsIcon sx={{ color: white }} />}
          path="/clients"
        />
        <ListLink
          open={open}
          text="Projects"
          icon={<AccountTreeOutlined sx={{ color: white }} />}
          path="/projects"
        />
        <ListLink
          open={open}
          text="Time Tracker"
          icon={<HourglassBottomOutlined sx={{ color: white }} />}
          path="/timetracker"
        />
      </List>
      {open ? (
        <Subtitle text="Performance" />
      ) : (
        <Divider sx={{ background: white }} />
      )}
      <List disablePadding={open}>
        <ListLink
          open={open}
          text="Revenue"
          icon={<TrackChangesOutlined sx={{ color: white }} />}
          path="/revenue"
        />
        <ListLink
          open={open}
          text="Monthly"
          icon={<InsightsOutlined sx={{ color: white }} />}
          path="/monthly"
        />
        <ListLink
          open={open}
          text="Yearly"
          icon={<DonutLargeIcon sx={{ color: white }} />}
          path="/yearly"
        />
      </List>
    </>
  );
};

export default function Sidebar() {
  const tabletBreakpoint = useMediaQuery("(max-width:900px)");

  const [open, setOpen] = useIsSidebarOpen();

  return (
    <Box>
      {tabletBreakpoint ? (
        <>
          <Stack direction="row" justifyContent="space-between" padding={2}>
            <Logo color={black} background={white} />
            <BurgerMenu
              color="inherit"
              icon={open ? <ArrowBackIosNewIcon /> : <MenuIcon />}
            />
          </Stack>
          <SwipeableDrawer
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
          >
            <Stack
              sx={{ background: darkBlue, height: "100%", padding: "12px" }}
              justifyContent="space-between"
            >
              <Navigation open={open} />
              <Username open={open} />
            </Stack>
          </SwipeableDrawer>
        </>
      ) : (
        <Stack>
          <AppBar
            position="fixed"
            sx={{
              width: `calc(100% - (${
                open ? drawerWidth : drawerWidthMobile
              }px))`,
              background: "transparent",
              boxShadow: "none",
            }}
          >
            <Toolbar>
              <BurgerMenu
                sx={{
                  "&:hover": { background: darkBlue, color: white },
                  position: "absolute",
                  left: open ? "-20px" : "-16px",
                  top: "18px",
                  zIndex: "101",
                  background: white,
                  color: black,
                }}
                icon={open ? <ArrowBackIosNewIcon /> : <ArrowForwardIos />}
              />
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open} sx={{ zIndex: "100" }}>
            <Box>
              <DrawerHeader>
                <Logo color={white} background={darkBlue} />
              </DrawerHeader>
              <Navigation open={open} />
            </Box>
            <Username open={open} />
          </Drawer>
        </Stack>
      )}
    </Box>
  );
}
